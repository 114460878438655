var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 九月上海会议报名记录 ")]},proxy:true},{key:"input",fn:function(){return [_c('a-input',{attrs:{"placeholder":"请输入姓名","allowClear":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('a',{staticClass:"left",attrs:{"href":_vm.$config.target + '/hxclass-management/kfxh_sing_up/exportKfxhSingUp',"target":"_blank","rel":"noopener noreferrer"}},[_c('a-button',{staticStyle:{"margin-right":"10px"}},[_vm._v("导出表格")])],1),_c('a-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":function($event){_vm.pageNumber=1,_vm.FormData()}}},[_vm._v("搜索")]),_c('a-button',{staticClass:"all_boder_btn",on:{"click":function($event){_vm.name=''}}},[_vm._v("重置")])]},proxy:true}])}),_c('div',{staticClass:"table-box"},[_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item,index) { return index; },"columns":_vm.columns,"loading":_vm.loading,"data-source":_vm.tableData,"locale":{ emptyText: '暂无数据' },"pagination":{
        total: _vm.total,
        current: _vm.pageNumber,
        defaultPageSize: _vm.pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return ("共" + total + "条");
        },
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.pageNumber - 1) * _vm.pageSize + i + 1)+" ")])]}},{key:"operation",fn:function(item){return [_c('div',{staticClass:"btn_router_link"},[(item.isConfirm == 0)?_c('a-popconfirm',{attrs:{"title":"是否确认状态?","ok-text":"确认","cancel-text":"取消"},on:{"confirm":function($event){return _vm.onOk(item)}}},[_c('a',[_vm._v("确认")])]):_c('span',[_vm._v("-")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }